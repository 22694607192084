var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hsm"},[_c('div',{staticClass:"vx-row mt-2"},[_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},_vm._l((_vm.messageTypes),function(type){return _c('vs-radio',{key:type.text,staticClass:"mr-4",attrs:{"vs-name":type.text,"vs-value":type.text},model:{value:(_vm.hsm.type),callback:function ($$v) {_vm.$set(_vm.hsm, "type", $$v)},expression:"hsm.type"}},[_vm._v("\n        "+_vm._s(type.text)+"\n      ")])}),1)],1),_vm._v(" "),_c('vs-divider'),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-0",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .elementName.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .elementName.placeholder[_vm.languageSelected],"danger":_vm.validations.emptyHsmName && !_vm.hsm.elementName,"danger-text":_vm.lang.audiences.add.wizard.validations.emptyHsmName[_vm.languageSelected]},model:{value:(_vm.hsm.elementName),callback:function ($$v) {_vm.$set(_vm.hsm, "elementName", $$v)},expression:"hsm.elementName"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-5",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .languageCode.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .languageCode.placeholder[_vm.languageSelected],"danger":_vm.validations.emptyHsmLanguageCode && !_vm.hsm.languageCode,"danger-text":_vm.lang.audiences.add.wizard.validations.emptyHsmLanguageCode[
            _vm.languageSelected
          ]},model:{value:(_vm.hsm.languageCode),callback:function ($$v) {_vm.$set(_vm.hsm, "languageCode", $$v)},expression:"hsm.languageCode"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-5",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .namespace.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .namespace.placeholder[_vm.languageSelected]},model:{value:(_vm.hsm.namespace),callback:function ($$v) {_vm.$set(_vm.hsm, "namespace", $$v)},expression:"hsm.namespace"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'6',"vs-sm":'6'}},[_c('vs-input',{staticClass:"inputx w-full mt-5",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .campaignAlias.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .campaignAlias.placeholder[_vm.languageSelected]},model:{value:(_vm.hsm.campaignAlias),callback:function ($$v) {_vm.$set(_vm.hsm, "campaignAlias", $$v)},expression:"hsm.campaignAlias"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vx-row mt-2"},[_c('vs-list',[_c('vs-list-header',{attrs:{"icon-pack":"feather","icon":"icon-code","title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .variables[_vm.languageSelected]}}),_vm._v(" "),_c('draggable',{staticStyle:{"overflow-y":"auto","max-height":"250px"},attrs:{"id":"variables"},model:{value:(_vm.hsm.parameters),callback:function ($$v) {_vm.$set(_vm.hsm, "parameters", $$v)},expression:"hsm.parameters"}},_vm._l((_vm.hsm.parameters),function(text,index){return _c('vs-list-item',{key:index,staticStyle:{"cursor":"move"},attrs:{"icon-pack":"feather","icon":"icon-chevron-right","rounded":"","subtitle":_vm.hsm.parameters[index]}},[_c('vs-button',{attrs:{"radius":"","type":"flat","icon":"clear"},on:{"click":function($event){_vm.removeVariable(index)}}})],1)}),1)],1)],1),_vm._v(" "),_c('vs-input',{staticClass:"inputx w-full mt-2 mb-base",attrs:{"placeholder":((_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
          .variableInputPlaceholder[_vm.languageSelected]) + " " + (_vm.hsm.parameters.length + 1)),"danger":_vm.validations.hsmElementVariables,"danger-text":_vm.lang.audiences.add.wizard.validations.hsmElementVariables[
        _vm.languageSelected
      ]},on:{"keypress":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addVariable($event)}},model:{value:(_vm.newVariable),callback:function ($$v) {_vm.newVariable=$$v},expression:"newVariable"}}),_vm._v(" "),(_vm.hsm.type === 'Template')?_c('div',{staticClass:"vx-row"},[_c('vs-list-header',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-image","title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .header.title[_vm.languageSelected]}}),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-0",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .header.image.url[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .header.image.urlPlaceholder[_vm.languageSelected]},model:{value:(_vm.hsm.header.image.url),callback:function ($$v) {_vm.$set(_vm.hsm.header.image, "url", $$v)},expression:"hsm.header.image.url"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-select',{staticClass:"mt-5 w-full",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .header.image.format[_vm.languageSelected]},model:{value:(_vm.hsm.header.image.type),callback:function ($$v) {_vm.$set(_vm.hsm.header.image, "type", $$v)},expression:"hsm.header.image.type"}},_vm._l((_vm.imageFormats),function(item){return _c('vs-select-item',{key:item,attrs:{"value":item,"text":item}})}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }