<template>
  <section class="hsm">
    <div class="vx-row mt-2">
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-radio
          v-for="type in messageTypes"
          :key="type.text"
          v-model="hsm.type"
          :vs-name="type.text"
          :vs-value="type.text"
          class="mr-4"
        >
          {{ type.text }}
        </vs-radio>
      </vs-col>
    </div>
    <vs-divider />
    <div class="vx-row">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-0"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .elementName.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .elementName.placeholder[languageSelected]
          "
          v-model="hsm.elementName"
          :danger="validations.emptyHsmName && !hsm.elementName"
          :danger-text="
            lang.audiences.add.wizard.validations.emptyHsmName[languageSelected]
          "
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-5"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .languageCode.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .languageCode.placeholder[languageSelected]
          "
          v-model="hsm.languageCode"
          :danger="validations.emptyHsmLanguageCode && !hsm.languageCode"
          :danger-text="
            lang.audiences.add.wizard.validations.emptyHsmLanguageCode[
              languageSelected
            ]
          "
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-5"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .namespace.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .namespace.placeholder[languageSelected]
          "
          v-model="hsm.namespace"
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'6'" :vs-sm="'6'">
        <vs-input
          class="inputx w-full mt-5"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .campaignAlias.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .campaignAlias.placeholder[languageSelected]
          "
          v-model="hsm.campaignAlias"
        />
      </vs-col>
    </div>
    <div class="vx-row mt-2">
      <vs-list>
        <vs-list-header
          icon-pack="feather"
          icon="icon-code"
          :title="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .variables[languageSelected]
          "
        ></vs-list-header>
        <draggable
          v-model="hsm.parameters"
          id="variables"
          style="overflow-y: auto; max-height: 250px"
        >
          <vs-list-item
            v-for="(text, index) in hsm.parameters"
            :key="index"
            icon-pack="feather"
            icon="icon-chevron-right"
            rounded
            :subtitle="hsm.parameters[index]"
            style="cursor: move"
          >
            <vs-button
              radius
              type="flat"
              icon="clear"
              @click="removeVariable(index)"
            ></vs-button>
          </vs-list-item>
        </draggable>
      </vs-list>
    </div>
    <vs-input
      class="inputx w-full mt-2 mb-base"
      :placeholder="
        `${
          lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .variableInputPlaceholder[languageSelected]
        } ${hsm.parameters.length + 1}`
      "
      v-model="newVariable"
      @keypress.enter="addVariable"
      :danger="validations.hsmElementVariables"
      :danger-text="
        lang.audiences.add.wizard.validations.hsmElementVariables[
          languageSelected
        ]
      "
    />
    <div class="vx-row" v-if="hsm.type === 'Template'">
      <vs-list-header
              icon-pack="feather"
              icon="icon-image"
              class="w-full"
              :title="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .header.title[languageSelected]
          "
      ></vs-list-header>
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
                class="inputx w-full mt-0"
                :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .header.image.url[languageSelected]
          "
                :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .header.image.urlPlaceholder[languageSelected]
          "
                v-model="hsm.header.image.url"
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-select v-model="hsm.header.image.type"
                    class="mt-5 w-full"
                   :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .header.image.format[languageSelected]
          "
        >
          <vs-select-item
                  :key="item"
                  :value="item"
                  :text="item"
                  v-for="item in imageFormats"
          />
        </vs-select>
      </vs-col>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'Hsm',
  components: {
    draggable
  },
  data() {
    return {
      variables: [],
      newVariable: null,
      validations: {
        emptyHsmName: false,
        emptyHsmLanguageCode: false,
        hsmElementVariables: false
      },
      messageTypes: [{ text: 'HSM' }, { text: 'Template' }],
      imageFormats: ["JPG", "JPEG", "PNG"]
    }
  },
  computed: {
    ...mapState(['languageSelected', 'lang']),
    ...mapGetters('audiences', ['hsm'])
  },
  methods: {
    addVariable() {
      if (
        this.newVariable &&
        this.newVariable.length > 0 &&
        !this.hsm.parameters.includes(this.newVariable)
      ) {
        this.hsm.parameters.push(`${this.newVariable}`)
        this.newVariable = ''
        window.scrollTo(0, document.body.scrollHeight)
        var container = this.$el.querySelector('#variables')
        container.scrollTop = container.scrollHeight - 100
        this.validateHSM()
      }
    },
    removeVariable(index) {
      this.hsm.parameters.splice(index, 1)
      this.validateHSM()
    },
    validate() {
      this.validations.emptyHsmName = !this.hsm.elementName
      this.validations.emptyHsmLanguageCode = !this.hsm.languageCode
      this.validateHSM()

      return !Object.values(this.validations).some(elem => elem)
    },
    validateHSM() {
      this.validations.hsmElementVariables =
        'chatclub_southcone_welcome_v1' === this.hsm.elementName &&
        this.hsm.parameters.length !== 2
    }
  },
  mounted() {
    window.scrollTo(0, document.body.scrollHeight)
  }
}
</script>
